import './index.css';
import './App.css';
import * as styles from './Home.module.css'
import { Helmet } from "react-helmet"

import cloud2 from '../images/cloud2.png';

import React, { Component } from "react";
import Checkout from '../components/Checkout'
import Nav from '../components/Nav'

import gsap from 'gsap'
import { navigate } from 'gatsby';

export default class ViewCart extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitNav = this.handleSubmitNav.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.form = React.createRef();
        this.orders = React.createRef();
        this.display = React.createRef();

        this.savedCart = []


        this.state = {
            orders: [],
            productsDisplay: '',
        }
    }

    handleSubmitNav = (event) => {

        if (this.savedCart == undefined || this.savedCart.length === 0) {
            alert("cart is empty")
            event.preventDefault();
            return;
        }
        else
            this.setState({ isCheckout: true })

        this.handleSubmit();
        this.form.current.submit()
    }

    handleClear(event) {
        sessionStorage.clear()
        this.setState({ orders: [] })

    }


    handleSubmit() {

        var pushOrder = ''
        var pushDisplay = ''


        for (let i = 0; i < this.savedCart.length; i++) {
            pushOrder = this.savedCart[i].push + ", " + pushOrder;
            pushDisplay = this.savedCart[i].display + ", " + pushDisplay;
        }

        /*

        for (let i = 0; i < this.state.orders.length; i++) {
            pushOrder = this.state.orders[i].push + ", " + pushOrder;
            pushDisplay = this.state.orders[i].display + ", " + pushDisplay;
        }
*/
        this.orders.current.value = pushOrder
        this.display.current.value = pushDisplay

    }

    componentDidMount() {
        this.savedCart = JSON.parse(window.sessionStorage.getItem('orders'))

        if (this.savedCart != undefined) {
            //this.handleSubmitNav(this.savedCart);
            this.setState({ orders: this.savedCart })
        }

        if (this.state.orders.length == 0) {
            gsap.from("#emptycart", {
                duration: 4,
                opacity: 0,
            });

        }



    }

    componentWillUnmount() {

    }

    orderDelete = (order) => {

        var orders = this.state.orders
        orders.splice(order, 1);
        window.sessionStorage.setItem('orders', JSON.stringify(orders))
        this.setState({ orders: orders })
    }

    orderChange = (event, order) => {
        let btn
        if (!event.target)
            btn = event
        else {
            btn = event.target.value
        }

        var orders = this.state.orders


        if (btn === 'up') {
            if (orders[order].quantity < 5)
                orders[order].quantity = parseInt(orders[order].quantity) + 1
        }
        else if (btn === 'down') {
            if (orders[order].quantity > 1)
                orders[order].quantity = parseInt(orders[order].quantity) - 1

        }

        window.sessionStorage.setItem('orders', JSON.stringify(orders))
        this.setState({ orders: orders })


    }

    render() {
        //console.log(this.state.orders)
        const style = {
            backgroundColor: this.state.orders.length > 0 ? "#FFF" : "#000",
            color: this.state.orders.length > 0 ? "#000" : "#FFF",
            paddingTop: '10vh',
            height: '90vh',
            width: '100vw',
        }
        return (<>
            <Helmet>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      
              <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
              <link href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap" rel="stylesheet" />
            </Helmet>
            <Nav onlyHome={true} />
            <Checkout onClick={this.handleSubmitNav} cart={this.state.orders.length} />
            <div style={style}>
                <center >
                    <div className={styles.container}>
                        {Array.from({ length: this.state.orders.length }, (value, order) =>
                            <>
                                <div key={order} className={styles.spacer}>
                                    <h3>{this.state.orders[order].variant + ' - ' + this.state.orders[order].name}
                                        <br />
                                        <label>Size: {this.state.orders[order].size}</label><br/>
                                        <label>Quantity: </label><button value="down" onClick={e => this.orderChange(e, order)} className={styles.card}>-</button>{this.state.orders[order].quantity}<button value="up" onClick={e => this.orderChange(e, order)} className={styles.card}>+</button>
                                        
                                        <button onClick={e => this.orderDelete(order)} className={styles.lilcard}>Delete</button>
                                    </h3>
                                </div>
                            </>
                        )}
                        <div className={styles.centerbox}>
                            {this.state.orders.length > 0 ? "" : <>           
                                <img className={styles.cloud1} src={cloud2} alt="" />
                                <img className={styles.cloud2} src={cloud2} alt="" />
                                <img className={styles.cloud3} src={cloud2} alt="" />
                                <img className={styles.cloud4} src={cloud2} alt="" />
                            <i id="emptycart">cart is empty :(</i></>}
                        </div>
                    </div>
                </center>

                <form action="https://server.cozyclub.us/create-checkout-session" ref={this.form} onSubmit={this.handleSubmitNav} method="POST">


                    <input type="hidden" name="orders" id="orders" ref={this.orders} />
                    <input type="hidden" name="display" id="display" ref={this.display} />

                </form>
            </div>
        </>
        );
    }
}