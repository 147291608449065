import React from "react";
import "../pages/App.css";
import * as styles from "../pages/Home.module.css";
import { navigate } from "gatsby";

function jumpTo(dest, id) {
  navigate(dest);
}
const Nav = (props) => {
  const hasItems = props.cart > 0;
  const mult = props.cart > 1 ? "items" : "item";
  const style = {
    position: 'fixed'
  }
  if(typeof props.sticky !== 'undefined' && props.sticky)
  {
    style.position = 'sticky'
    style.background = 'linear-gradient(0.25turn, #0f0c29, #302B63)'
  }


  return (
    <>
      <div id="nav" style={style} className={styles.navbar}>
        <span onClick={() => jumpTo("/", `logo`)} className={styles.logoTop}>
          <center>
            Go Home
            <span class="material-icons">
              home
            </span>
          </center>
        </span>

        {props.onlyHome ? (
          <></>
        ) : (
          <>
            <span onClick={() => jumpTo("/viewcart", `view`)} className={styles.cartbar}>
              <center>
                View Cart
                <span
                  
                  class="material-icons"
                >
                  shopping_cart_checkout
                </span>
              </center>
            </span>
          </>
        )}
      </div>
    </>
  );
};
export default Nav;
